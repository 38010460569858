<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <h3 class="podnaslov">SERVIS</h3>
        <main>
            <div class="text_container">
                <div class="text">
                    Naši djelatnici izvrsno poznaju tehničke odlike i mogućnosti strojeva; isto tako poznaju posebnosti čišćenja, održavanja i uređenja po gospodarskim granama, te Vam stoga možemo zagarantirati kvalitetnu kupnju, servis i održavanje Vašeg uređaja.<br/><br/>
                    Naša ovlaštena servisna služba školovana je kod originalnih proizvodača te raspolažemo bogatom i probranom zalihom rezervnih dijelova.<br/><br/>
                    Uz strojeve iz naše ponude dobivaju se detaljno ilustrirane upute na hrvatskom jeziku, dajemo puno jamstvo, servis, obuku i tehničku potporu. Uvijek ste dobrodošli obratiti nam se s pitanjem ili prijedlogom.<br/><br/>
                    Za strojeve iz naše ponude nudimo i svu pripadajuću opremu, rezervne dijelove, sredstva za pranje i čišćenje te visokotlačna crijeva i crijeva za čišćenje kanala koja sami izrađujemo!!!<br/><br/>
                    Na ovim stranicama nalazi se samo mali dio FRANK, Nilfisk Alto, Stihl programa. Za detaljnije informacije kontaktirajte nas telefonom ili e-mailom.
                </div>
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    
    export default {
        name: 'App',

        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                
            }
        },
        created () {
        }
    }
</script>

<style>
main {
    padding-top: 0.5rem;
    padding-bottom: 5rem;
}
.naslov {
    margin-left: 22rem;
}
.text_container {
    margin-right: 17rem;
    margin-left: 17rem;
    margin-top: 2rem;
    text-align: left;
    border: solid 0.4em white;
    border-top-color: black;
    border-bottom-color: black;
}
.text {
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 16px;
}
.podnaslov {
    text-align: left;
    margin-left: 22rem;
    margin-top: 2rem;
    font-size: 25px;
    color: black;
}

@media only screen and (max-width: 1200px) {
    [class*="text_container"] {
        margin-right: 8rem;
        margin-left: 8rem;
    }
    [class*="naslov"] {
        margin-left: 9rem;
    }
    [class*="text"] {
        margin-right: 3rem;
        margin-left: 3rem;
    }
}

@media only screen and (max-width: 768px) {
    main {
        padding-bottom: 1.5rem;
    }
    [class*="text_container"] {
        margin-right: 2rem;
        margin-left: 2rem;
        margin-top: 1rem;
    }
    [class*="naslov"] {
        margin-left: 4rem;
    }
    [class*="text"] {
        margin-right: 1.3rem;
        margin-left: 1.3rem;
        font-size: 14px;
    }
    .podnaslov {
        margin-left: 3.2rem;
        margin-right: 2rem;
        font-size: 20px;
    }
}
</style>
